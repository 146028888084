.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  max-width: 500px;
  margin: 0 auto;
}

.description {
  color: #030303;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  line-height: 24px;
  text-align: center;
  margin: 0 auto;
  max-width: 335px;
  padding: 0 16px;
  margin-bottom: 40px;
} 