.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  color: #030303;
  fill: #030303;
  font-size: 18px;
  width: 18px;
  height: 16px;
} 