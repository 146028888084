.verificationPage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f8f9fa;
}

.verificationContainer {
  max-width: 500px;
  width: 100%;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.verificationIcon {
  font-size: 60px;
  margin-bottom: 20px;
}

.success {
  color: #28a745;
}

.error {
  color: #dc3545;
}

.verificationMessage {
  margin-bottom: 20px;
  font-size: 18px;
}

.homeButton {
  margin-top: 20px;
  display: flex;
  justify-content: center;
} 