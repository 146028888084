.locationGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  max-width: 320px;
  margin: 40px auto 0;
}

.locationCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.locationCard {
  width: 120px;
  height: 120px;
  background-color: #e0e0e0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 10px;
}

.locationCard:hover {
  background-color: #d0d0d0;
}

.locationCard.selected {
  background-color: #e9d5ff;
  border: 2px solid #8a2be2;
}

.locationIcon {
  width: 40px;
  height: 40px;
  color: #8a2be2;
  fill: #8a2be2;
}

.locationName {
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  color: #030303;
}

.locationQuestion {
  color: #030303;
  font-size: 20px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  margin: 30px auto;
  padding: 0 16px;
}

.nextButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.nextButton {
  cursor: pointer;
  width: 133px;
  height: 40px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #000000;
  color: #ffffff;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  line-height: 24px;
  outline: none;
  transition: background-color 0.2s ease;
}

.nextButton:hover {
  background-color: #2c2c2c;
}

.nextButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
} 