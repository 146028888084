.imageContainer {
  width: 350px;
  height: 290px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto 12px;
}

.heading {
  color: #030303;
  font-size: 22px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.description {
  color: #030303;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  line-height: 24px;
  text-align: center;
  margin: 0 auto;
  max-width: 335px;
  padding: 0 10px;
  margin-bottom: 30px;
}

/* Testimonial styles */
.testimonialAvatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 10px auto 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.testimonialQuote {
  color: #030303;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  line-height: 24px;
  text-align: center;
  margin: 0 auto 20px;
  max-width: 335px;
  padding: 0 10px;
  font-style: italic;
}

.testimonialAuthorContainer {
  text-align: center;
  margin-bottom: 30px;
}

.testimonialAuthorName {
  color: #545454;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  line-height: 24px;
  margin-bottom: 4px;
}

.testimonialAuthorOccupation {
  color: #545454;
  font-size: 12px;
  font-family: 'Lexend Deca', sans-serif;
  line-height: 16px;
}

.testimonialLink {
  color: #545454;
  text-decoration: none;
}

.testimonialLink:hover {
  text-decoration: underline;
} 