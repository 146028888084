.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal_content {
  background: white;
  padding: 40px;
  border-radius: 26px;
  max-width: 600px;
  width: 100%;
  position: relative;
  z-index: 1001;
}

.buttons {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 32px;
} 