.trialContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  max-width: 500px;
  margin: 0 auto;
}

.numberCard {
  width: 100px;
  height: 100px;
  background-color: #8a2be2;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
}

.number {
  color: #ffffff;
  font-size: 36px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 700;
}

.title {
  color: #030303;
  font-size: 22px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.description {
  color: #030303;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  line-height: 24px;
  text-align: center;
  margin: 0 auto;
  max-width: 335px;
  padding: 0 10px;
  margin-bottom: 30px;
} 