.container {
  padding: 0 16px;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 20px;
}

.featureContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}

.featureHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  gap: 8px;
}

.featureIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.featureTitle {
  color: #030303;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

.featureDescription {
  color: #030303;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  line-height: 24px;
  text-align: center;
  margin-bottom: 24px;
  max-width: 400px;
  padding: 0 16px;
} 