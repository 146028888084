.iconCard {
  width: 70px;
  height: 68px;
  background-color: #e0e0e0;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto 30px;
}

.communityHeading {
  color: #030303;
  font-size: 20px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  margin: 0 auto 30px;
  max-width: 335px;
  padding: 0 16px;
}

.communityDescription {
  color: #030303;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  line-height: 24px;
  text-align: center;
  margin: 0 auto;
  max-width: 335px;
  padding: 0 16px;
  margin-bottom: 40px;
} 