.onboarding-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0;
  width: 100%;
}

.onboarding-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.onboarding-description {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
  max-width: 600px;
}

.onboarding-actions {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}

.step-content {
  margin: 0px 0;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* For any custom form elements in the steps */
.onboarding-form {
  width: 100%;
  max-width: 500px;
}

.next-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.next-button:hover {
  background-color: #3a7bc8;
}

.back-button {
  background-color: transparent;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}

.back-button:hover {
  background-color: #f5f5f5;
}

.options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 343px;
  margin: 0 auto;
}

.location-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  max-width: 320px;
  margin: 20px auto 0;
} 