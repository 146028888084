.backArrowContainer {
  position: absolute;
  top: 0;
  left: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.backArrow {
  color: #8a2be2;
  fill: #8a2be2;
  font-size: 21px;
  width: 21px;
  height: 24px;
}

.pageTitle {
  color: #030303;
  font-size: 20px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  width: 100%;
  padding: 0;
  margin: 0;
}

.pageNavContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  margin-top: 15px;
  height: 24px;
}

.nextButton {
  cursor: pointer;
  width: 133px;
  height: 40px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #000000;
  color: #ffffff;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  line-height: 24px;
  outline: none;
  margin: 20px auto;
  display: block;
}

.nextButton:hover {
  background-color: #2c2c2c;
}

.nextButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Container for positioning the next button at the bottom */
.bottomButtonContainer {
  position: relative;
  bottom: auto;
  left: auto;
  right: auto;
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-bottom: 30px;
  z-index: 10;
} 