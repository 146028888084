.trialOptionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 16px;
  max-width: 500px;
  margin: 0 auto;
}

.optionCard {
  width: 130px;
  height: 28px;
  background-color: #ffffff;
  border-radius: 27px;
  border: 1px solid #8462d7;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 24px 10px;
  padding: 0 10px;
  align-self: flex-start;
}

.planeIcon {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  color: #8a2be2;
  fill: #8a2be2;
}

.planeText {
  color: #000000;
  font-size: 11px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  margin-left: 8px;
}

.description {
  color: #030303;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  line-height: 24px;
  text-align: center;
  margin: 0 auto;
  max-width: 335px;
  padding: 0 10px;
  margin-bottom: 20px;
}

.smallDescription {
  color: #030303;
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
  line-height: 20px;
  text-align: left;
  width: 100%;
  max-width: 335px;
  padding: 0 10px;
  margin-bottom: 20px;
  margin-left: 0;
  margin-right: auto;
}

.greyBox {
  width: 100%;
  max-width: 335px;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto 24px;
  align-self: center;
}

.priceTitle {
  color: #030303;
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 20px;
}

.priceOptions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.priceCard {
  width: 118px;
  height: 38px;
  background-color: #ffffff;
  border-radius: 23px;
  border: 1px solid #8462d7;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.priceText {
  color: #030303;
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
}

.selectedDot {
  width: 15px;
  height: 15px;
  background-color: #8a2be2;
  border-radius: 50%;
}

.unselectedDot {
  width: 15px;
  height: 15px;
  border: 1px solid #8a2be2;
  border-radius: 50%;
  background-color: transparent;
}

.helpText {
  color: #808080;
  font-size: 11px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 400;
  line-height: 13px;
  text-align: center;
} 