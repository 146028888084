.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  max-width: 500px;
  margin: 0 auto;
}

.iconCard {
  width: 160px;
  height: 160px;
  background-color: #ffffff;
  border-radius: 9999px;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
}

.icon {
  width: 70px;
  height: 70px;
  color: #8a2be2;
}

.title {
  color: #030303;
  font-size: 36px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  margin-bottom: 24px;
}

.description {
  color: #030303;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  line-height: 24px;
  text-align: center;
  margin: 0 auto;
  max-width: 335px;
  padding: 0 16px;
  margin-bottom: 40px;
}

.link {
  color: #8a2be2;
  text-decoration: underline;
  cursor: pointer;
} 