.onboardingContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff;
  padding: 0;
  position: relative;
}

.onboardingContent {
  flex: 1;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.contentArea {
  flex: 1;
  min-height: 300px;
  padding: 0;
}

/* Footer area container */
.footerArea {
  width: 100%;
  margin-top: auto;
  padding-bottom: 30px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .onboardingContent {
    padding: 10px 15px 0 15px;
  }
} 