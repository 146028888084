.giftContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  max-width: 500px;
  margin: 0 auto;
}

.giftImage {
  width: 160px;
  height: 160px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 30px auto;
}

.title {
  color: #030303;
  font-size: 36px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  margin-bottom: 24px;
}

.description {
  color: #030303;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  line-height: 24px;
  text-align: center;
  margin: 0 auto;
  max-width: 335px;
  padding: 0 16px;
  margin-bottom: 40px;
} 