.title {
  margin-top: 40px;
  margin-bottom: 50px;
}

.details_wrapper,
.text_block {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1000px;
  margin: 0 auto;
  align-items: center;
}

.details_wrapper {
  text-align: center;
}

.details_wrapper h4 {
  margin-top: 120px;
  margin-bottom: 0;
}

.details_wrapper h5 {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 40px;
}

.error_message {
  background-color: #ffecec;
  color: #ff4646;
  padding: 15px 20px;
  border-radius: 8px;
  border-left: 4px solid #ff4646;
  margin-bottom: 30px;
  width: 100%;
  text-align: left;
  font-size: 16px;
  line-height: 1.4;
}

.block img {
  width: 100%;
  max-height: 800px;
  border-radius: 25px;
  object-fit: cover;
  margin-bottom: 40px;
}

.block {
  font-size: 18px;
  width: 100%;
}

.text_block {
  width: 100%;
  padding: 60px 30px;
  border-radius: 25px;
  background-color: #f5f6f6;
  text-align: left;
}


/* Update these styles */
.text_block h1,
.text_block h2,
.text_block h3,
.text_block h4,
.text_block h5,
.text_block h6,
.text_block p,
.text_block ul,
.text_block ol,
.text_block strong {
  text-align: left;
  width: 100%;
  display: block;
}

.text_block strong {
  font-weight: bold;
}

/* Add this new style */
.text_block * {
  text-align: left;
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
}

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f5f6f6;
  border-radius: 26px;
  transition: 0.3s;
  cursor: pointer;
}
.card:hover {
  transform: scale(0.95);
}
.card img {
  object-fit: cover;
  width: 100%;
  height: 750px;
  border-radius: 30px;
}
.card div {
  padding: 30px;
}

.card_title {
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.card p {
  font-size: 18px;
}

@media screen and (max-width: 1200px) {
  .card img {
    height: 625px;
  }
  .card_title {
    font-size: 48px;
  }
}
@media screen and (max-width: 1000px) {
  .card img {
    height: 525px;
  }
}
@media screen and (max-width: 768px) {
  .details_wrapper h4 {
    margin-top: 50px;
  }
  .details_wrapper h5 {
    font-size: 18px;
  }
  .block img {
    width: 100%;
  }
  .text_block {
    padding: 40px 30px;
    font-size: 16px;
  }
  .pagination {
    gap: 10px;
  }
  .card img {
    height: 430px;
  }
  .card_title {
    font-size: 14px;
  }
  .card_title {
    font-size: 40px;
  }
  .pagination_btn {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
  .further_btn,
  .prev_btn {
    height: 50px;
    font-size: 20px;
  }
  .further_btn::after,
  .prev_btn::before {
    width: 38px;
    height: 38px;
  }
}

@media screen and (max-width: 655px) {
  .title {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .prev_btn,
  .further_btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 0;
    padding: 5px;
  }
  .prev_btn::before,
  .further_btn::after {
    background-color: transparent;
  }
  .prev_btn::before {
    padding-bottom: 0;
    padding-right: 3px;
  }
  .further_btn::after {
    padding-top: 5px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 550px) {
  .details_wrapper h4 {
    margin-top: 30px;
  }
  .details_wrapper h5 {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .block img {
    width: 100%;
    margin-bottom: 20px;
  }
  .text_block {
    padding: 30px 20px;
    font-size: 14px;
  }
  .card {
    width: 100%;
  }
  .card img {
    height: 370px;
  }
  .card_title {
    font-size: 34px;
  }
  .card div {
    padding: 20px;
  }
}

@media screen and (max-width: 400px) {
  .card {
    width: 100%;
  }
  .card img {
    height: 300px;
  }
  .card_title {
    font-size: 30px;
  }
  .pagination_btn {
    width: 40px;
    height: 40px;
    font-size: 13px;
  }
  .further_btn,
  .prev_btn {
    width: 40px;
    height: 40px;
    padding: 10px 8px;
  }
  .open_menu ul li {
    font-size: 32px;
  }
}

@media screen and (max-width: 850px) {
  .details_wrapper {
    padding: 0 20px;
  }
}
