.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  max-width: 500px;
  margin: 0 auto;
}

.title {
  color: #030303;
  font-size: 24px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 40px;
}

.subtitleText {
  color: #000000;
  font-size: 14px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  margin-bottom: 24px;
}

.inputField {
  width: 100%;
  max-width: 291px;
  height: 42px;
  padding: 0px 16px;
  border: 0;
  box-sizing: border-box;
  border-radius: 11px;
  background-color: #f5f5f5;
  color: #000000;
  font-size: 14px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  line-height: 42px;
  outline: none;
  margin-bottom: 20px;
}

.inputField::placeholder {
  color: #94a3b8;
}

.button {
  cursor: pointer;
  width: 207px;
  height: 48px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
  background-color: #030303;
  color: #ffffff;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  line-height: 24px;
  outline: none;
  margin-bottom: 24px;
  transition: all 0.2s ease;
}

.button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  box-shadow: none;
  opacity: 0.7;
}

.loginText {
  color: #000000;
  font-size: 14px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}

.loginLink {
  color: #8a2be2;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

.passwordContainer {
  position: relative;
  width: 100%;
  max-width: 291px;
  margin-bottom: 10px;
}

.passwordField {
  width: 100%;
  height: 42px;
  padding: 0px 40px 0px 16px;
  border: 0;
  box-sizing: border-box;
  border-radius: 11px;
  background-color: #f5f5f5;
  color: #000000;
  font-size: 14px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  line-height: 42px;
  outline: none;
}

.passwordField::placeholder {
  color: #94a3b8;
}

.eyeButton {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eyeIcon {
  width: 24px;
  height: 24px;
  opacity: 0.7;
}

.passwordHint {
  color: #666666;
  font-size: 11px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  max-width: 300px;
  margin: 0 auto 20px;
} 