.questionIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 30px;
}

.questionIcon {
  color: #8a2be2;
  fill: #8a2be2;
  font-size: 27px;
  width: 27px;
  height: 32px;
}

.questionText {
  color: #030303;
  font-size: 20px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  margin: 0 auto 40px;
  padding: 0 16px;
}

.optionButton {
  cursor: pointer;
  width: 343px;
  height: 48px;
  padding: 0 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #e0e0e0;
  color: #030303;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  line-height: 24px;
  outline: none;
  margin: 10px auto;
  display: block;
  transition: background-color 0.2s ease;
}

.optionButton:hover {
  background-color: #d0d0d0;
}

.optionButton.selected {
  background-color: #8a2be2;
  color: #ffffff;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
} 