.loginPage {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  padding: 40px 0;
}

.loginContainer {
  width: 100%;
  max-width: 600px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .loginContainer {
    max-width: 90%;
  }
}

@media screen and (max-width: 500px) {
  .loginContainer {
    max-width: 95%;
  }
} 