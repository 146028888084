.header {
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  background-color: #ffffff;
  box-shadow: 2px -2px 10px rgba(3, 3, 3, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
} 