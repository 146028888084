.exploreIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 30px;
}

.exploreIcon {
  width: 50px;
  height: 50px;
  color: #000000;
  fill: #000000;
}

.exploreHeading {
  color: #030303;
  font-size: 20px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  margin: 0 auto 30px;
  max-width: 335px;
  padding: 0 16px;
}

.exploreDescription {
  color: #030303;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  line-height: 24px;
  text-align: center;
  margin: 0 auto;
  max-width: 335px;
  padding: 0 16px;
  margin-bottom: 40px;
}

.nextButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.nextButton {
  cursor: pointer;
  width: 133px;
  height: 40px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #000000;
  color: #ffffff;
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  line-height: 24px;
  outline: none;
  transition: background-color 0.2s ease;
}

.nextButton:hover {
  background-color: #2c2c2c;
} 