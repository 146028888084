.telegramLoginPage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #f5f5f5;
  padding: 20px 0 40px;
  position: relative;
  min-height: auto;
  height: auto;
}

.telegramLoginContainer {
  width: 100%;
  max-width: 600px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 20px 30px;
  margin-top: 20px;
  height: auto;
}

@media screen and (max-width: 768px) {
  .telegramLoginContainer {
    max-width: 90%;
  }
}

@media screen and (max-width: 500px) {
  .telegramLoginContainer {
    max-width: 95%;
  }
}

/* New styles for instruction-based UI */
.telegramLoginInstructions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.instructionSteps {
  width: 100%;
  margin-top: 10px;
}

.step {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
}

.stepNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
  margin-right: 20px;
  flex-shrink: 0;
}

.stepText {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.telegramLink {
  display: inline-block;
  text-decoration: none;
}

.command {
  background-color: #f4f4f4;
  padding: 3px 8px;
  border-radius: 4px;
  font-family: monospace;
  font-weight: 500;
}

.botName {
  font-weight: 600;
}

/* Styles for the code check form */
.telegramCodeCheckContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.processingState {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.messageState {
  margin-top: 30px;
  text-align: center;
}

.returnLink {
  margin-top: 30px;
  cursor: pointer;
  color: #0066cc;
}

.returnLink:hover {
  text-decoration: underline;
} 