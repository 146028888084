.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.spinnerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.spinnerWrapper p {
  font-size: 18px;
  color: #282828;
} 