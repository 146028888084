.error_popup {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 230, 230, 0.95);
  color: #d32f2f;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(211, 47, 47, 0.2);
  z-index: 1000;
  max-width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  border-left: 4px solid #d32f2f;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.error_content {
  margin: 0;
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 12px;
  line-height: 1.4;
}

.hide {
  opacity: 0;
} 